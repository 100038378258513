import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import NotFound from './components/NotFound';
import RegistrationPage from "./components/RegistrationPage";
import StartPage from "./components/StartPage";

const RouteResolver = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' exact element={<Dashboard/>} />
                <Route path='/login' exact element={<StartPage/>} />
                <Route path='/register' exact element={<RegistrationPage/>} />
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    )
}
export default RouteResolver;