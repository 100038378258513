import React, { useState, useEffect } from 'react';
import HttpClient from './HttpClient';

import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'



import {BsPencilSquare, BsTrash} from "react-icons/bs";

const Dashboard = () => {

    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([{}]);

    const api_url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        (async () => {
            try {
                let response = await HttpClient.get(api_url+'/users/@get-user');
                if (response.data.status === 2) {
                    window.location.href = '/login';
                } else {
                    setUser(response.data.user);
                    if (response.data.user.role === 'admin') {
                        response = await HttpClient.get(api_url+'/users/@get-data');
                        setUsers(response.data.users);
                    }
                }
            } catch (error) {
                // console.log('Error');
            }
        })();
    }, []);

    const logoutUser = async () => {
        await HttpClient.post(api_url+'/security/logout');
        window.location.href = '/login';
    };

    const [apiRequestPending, setApiRequestPending] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertText, setAlertText] = useState('');

    /* Delete User */
    const [deletedUserId, setDeletedUserId] = useState(null);
    const [deleteUserConfirmModalShow, setDeleteUserConfirmModalShow] = useState(false);
    const showUserDeleteConfirmModal = (userId) => {
        setDeletedUserId(userId);
        setDeleteUserConfirmModalShow(true);
    };
    const deleteUserConfirmModalHandleClose = () => setDeleteUserConfirmModalShow(false);
    const deleteUser = (id) => {
        setApiRequestPending(true);

        (async () => {
            try {
                const response = await HttpClient.delete(api_url+'/users/@delete-user/'+id);
                const responseData = response.data;
                if (responseData.status === 0) {
                    setAlertVariant('danger');
                    setAlertText(responseData.message);
                    setAlertShow(true);
                } else if (responseData.status === 1) {
                    setAlertVariant('success');
                    setAlertText(responseData.message);
                    setAlertShow(true);
                    setUsers(responseData.data.users);
                }
            } catch(error) {
                setAlertVariant('danger');
                setAlertText('Request Error');
                setAlertShow(true);
            }

            setApiRequestPending(false);
            deleteUserConfirmModalHandleClose();
        })();
    }

    /* Edit user data */
    const [editedUserId, setEditedUserId] = useState(null);
    const [userDataModalShow, setUserDataModalShow] = useState(false);
    
    const [userRole, setUserRole] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [userAddress, setUserAddress] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');

    const showUserDataModal = (userId) => {

        setEditedUserId(userId);
        
        if (userId === null) {
            setUserRole('customer');
            setUserEmail('');
            setUserName('');
            setUserAddress('');
            setUserPassword('');
            setUserConfirmPassword('');
            setUserDataModalShow(true);
        } else {
            setUserPassword('');
            setUserConfirmPassword('');
            setApiRequestPending(true);
            (async () => {
                try {
                    const response = await HttpClient.get(api_url+'/users/@get-user/'+userId);
                    const responseData = response.data;
                    if (responseData.status === 0) {
                        setAlertVariant('danger');
                        setAlertText(responseData.message);
                        setAlertShow(true);
                    } else if (responseData.status === 1) {
                        const userData = responseData.data.user;
                        setUserRole(userData.role);
                        setUserEmail(userData.email);
                        setUserName(userData.name);
                        setUserAddress(userData.address);
                        setUserDataModalShow(true);
                    }
                } catch(error) {
                    setAlertVariant('danger');
                    setAlertText('Request Error');
                    setAlertShow(true);
                }
            })();
            setApiRequestPending(false);
        }
    };
    const userDataModalHandleClose = () => {
        setUserDataModalShow(false);
    };

    const saveUserData = (event) => {
        event.preventDefault();
        setApiRequestPending(true);

        (async () => {
            try {
                const response = await HttpClient.post(api_url+'/users/@save-data', {
                    id: editedUserId,
                    role: userRole,
                    email: userEmail,
                    name: userName,
                    address: userAddress,
                    password: userPassword,
                    confirm_password: userConfirmPassword
                });
                const responseData = response.data;
                if (responseData.status === 0) {
                    setAlertVariant('danger');
                    setAlertText(responseData.message);
                    setAlertShow(true);
                } else if (responseData.status === 1) {
                    setAlertVariant('success');
                    setAlertText(responseData.message);
                    setAlertShow(true);
                    setUsers(responseData.data.users);
                    userDataModalHandleClose();
                }
            } catch(error) {
                setAlertVariant('danger');
                setAlertText('Request Error');
                setAlertShow(true);
            }
        })();

        setApiRequestPending(false);
    };

    return <div className="container">

        {user != null ? (

            user.role === 'admin' ? (
                <div>
                    <h1>Dashboard - Users</h1>
                    <div className="top-buttons">
                        <Button variant="outline-primary" size="sm" onClick={() => {showUserDataModal(null)}}>Add user</Button> 
                    </div>
                    
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                users.map(function(item, index) {
                                    return <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.role}</td>
                                        <td>{item.email}</td>
                                        <td>{item.name}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            <Button variant="outline-warning" size="sm" onClick={() => {showUserDataModal(item.id)}}><BsPencilSquare/></Button>
                                            <Button variant="outline-danger" size="sm" onClick={() => {showUserDeleteConfirmModal(item.id)}}><BsTrash/></Button>
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </Table>
                    <div className="buttons">
                        <Button variant="primary" size="sm" onClick={logoutUser}>Logout</Button>
                    </div>

                    {/* Delete User Confirm Modal */}
                    <Modal className={'delete-confirm-modal'} show={deleteUserConfirmModalShow} onHide={deleteUserConfirmModalHandleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Delete User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="message">Are you sure you want to delete user data ?</p>
                            <div className="row">
                                <div className="col-12-xs text-center">
                                    <Button className="confirm-button" variant="danger" disabled={apiRequestPending} onClick={() => deleteUser(deletedUserId)}>
                                        {apiRequestPending && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>}
                                        Delete
                                    </Button>
                                    <Button variant="outline-primary" onClick={deleteUserConfirmModalHandleClose} disabled={apiRequestPending}>Cancel</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* User Data Modal */}
                    <Modal id="userDataModal" show={userDataModalShow} onHide={userDataModalHandleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>User Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form id="user_data_form" onSubmit={saveUserData}>
                                <Form.Group className="form-group">
                                    <Form.Label>Role: </Form.Label>
                                    <Form.Select value={userRole} onChange={e => {setUserRole(e.target.value)}} required autoComplete='off'>
                                        <option value={0} disabled>Select user role</option>
                                        <option value="customer">Customer</option>
                                        <option value="admin">Admin</option>
                                    </Form.Select>      
                                </Form.Group>
                                <Form.Group className="form-group">
                                    <Form.Label>Email: </Form.Label>
                                    <Form.Control type="email" onChange={e => {setUserEmail(e.target.value)}} value={userEmail} placeholder="User email" required autoComplete='off'/>           
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Name: </Form.Label>
                                    <Form.Control type="text" onChange={e => {setUserName(e.target.value)}} value={userName} placeholder="User name" required autoComplete='off'/>           
                                </Form.Group>

                                <Form.Group className="form-group">
                                    <Form.Label>Address: </Form.Label>
                                    <Form.Control type="text" onChange={e => {setUserAddress(e.target.value)}} value={userAddress} placeholder="User address" required autoComplete='off'/>           
                                </Form.Group>

                                <Form.Group className="form-group password-inputs">
                                    <Form.Label>Password: </Form.Label>
                                    <Form.Control type="password" onChange={e => {setUserPassword(e.target.value)}} value={userPassword} placeholder="User password" autoComplete='off'/>           
                                    <Form.Control type="password" onChange={e => {setUserConfirmPassword(e.target.value)}} value={userConfirmPassword} placeholder="Confirm password" autoComplete='off'/>           
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" disabled={apiRequestPending} form="user_data_form" type="submit">
                                {apiRequestPending && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>} Save data
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    
                    <Alert show={alertShow} variant={alertVariant} onClose={() => setAlertShow(false)} dismissible>{alertText}</Alert>
                      
                </div>
            ) : (
                <div>
                    <h1>Dashboard - Users</h1>
                    <p>You are not have permission to list items</p>

                    <div className="buttons">
                        <Button variant="primary" size="sm" onClick={logoutUser}>Logout</Button>
                    </div>
                </div>
            )
        ) : ('')}
    </div>
}
export default Dashboard;