
import '../scss/registration-page.scss';
import React, {useRef, useState} from "react";
import HttpClient from './HttpClient';
import * as faceapi from "face-api.js";
import Webcam from "react-webcam";

const StartPage = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [isRequest, setIsRequest] = useState(false);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [formMessage, setFormMessage] = useState('');
    const [showFormMessage, setShowFormMessage] = useState(false);
    const [formMessageType, setFormMessageType] = useState('');

    const webCamRef = useRef();
    const canvasRef = useRef();
    const [showWebCam, setShowWebCam] = useState(false);

    const webCamCapture = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models')
        ]).then(() => {
            navigator.mediaDevices.getUserMedia({
                video: {},
            }, stream => webCamRef.current.srcObject = stream)
            .then(() => {
                setShowWebCam(true);
            })
            .catch (err => {
                formAlert('Video device not found', 'error', true);
            });
        });
    };

    const webCamOnPlay = () => {
        setInterval( async () => {
            canvasRef.current.innerHTML = faceapi.createCanvas(webCamRef.current);
            const displaySize = {
                width: 300,
                height: 300
            };
            faceapi.matchDimensions(canvasRef.current, displaySize);
            const detections = await faceapi.detectAllFaces('webcam', new faceapi.TinyFaceDetectorOptions());
            const resizedDetections = faceapi.resizeResults(detections, displaySize);
            canvasRef.current.getContext('2d').clearRect(0, 0, 400, 400);
            faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        }, 100);
    };

    const resetForm = () => {
        setEmail('');
        setName('');
        setPassword('');
        setPasswordConfirmation('');
    };

    const formAlert = (message, type, showStatus) => {
        setFormMessage(message);
        setFormMessageType(type);
        setShowFormMessage(showStatus);
    };

    const submitRegistrationForm = async () => {

        setIsRequest(true);
        formAlert('', '', false);
        setShowFormMessage(false);

        webCamCapture();

        setTimeout(async function () {
            try {
                const image_src = webCamRef.current.getScreenshot();
                const response = await HttpClient.post(api_url+'/security/register', {
                    image_src: image_src,
                    email: email,
                    name: name,
                    password: password,
                    password_confirmation: passwordConfirmation
                });
                const responseData = response.data;
                if (responseData.status === 0) {
                    formAlert(responseData.message, 'error', true);
                    setTimeout(function () {
                        window.location = '/register';
                    }, 2000);
                } else if (responseData.status === 1) {
                    formAlert(responseData.message, 'success', true);
                    resetForm();
                    setTimeout(function () {
                        window.location = '/';
                    }, 2000);
                } else {
                    formAlert('Request Error', 'error', true);
                }
            } catch (error) {
                if (error.response.status === 401) {
                    formAlert('Request Error', 'error', true);
                }
            }
            setIsRequest(false);
        }, 10000);
    };

    const redirectToLogin = () => {
        window.location = '/login';
    };

    return (
        <div className={'start-page-container'}>
            <div className={'start-page-wrapper'}>

                <div className={'form-title'}>
                    <span className={'title'}>Registration</span>
                </div>
                <div className={'form-wrapper'}>
                    <input type="email" value={email} disabled={isRequest} onChange={(e) => setEmail(e.target.value)} placeholder={'User Email'} required autoComplete={'off'}/>
                    <input type="text" value={name} disabled={isRequest} onChange={(e) => setName(e.target.value)} placeholder={'User Name'} required autoComplete={'off'}/>
                    <input type="password" value={password} disabled={isRequest} onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} required autoComplete={'off'}/>
                    <input type="password" value={passwordConfirmation} disabled={isRequest} onChange={(e) => setPasswordConfirmation(e.target.value)} placeholder={'Password Confirmation'} required autoComplete={'off'}/>

                    {showFormMessage ? <div className={`form-message ${formMessageType}`}>{formMessage}</div> : ''}

                    {showWebCam ?
                        <div className={'web-cam-wrapper'}>
                            <Webcam id={'webcam'} audio={false} width={400} height={400} ref={webCamRef} screenshotFormat={'image/jpeg'} onPlay={webCamOnPlay}/>
                            <canvas ref={canvasRef} width="940" height="650" className='app__canvas'></canvas>
                        </div>
                    : ''}

                    <div className={'actions-buttons-wrapper'}>
                        <button className={'actions-button'} disabled={isRequest} onClick={submitRegistrationForm}>Registration</button>
                        <button className={'actions-button'} disabled={isRequest} onClick={redirectToLogin}>Login</button>
                    </div>

                </div>
            </div>

        </div>
    )
}
export default StartPage;