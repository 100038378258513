
import '../scss/start-page.scss';
import React, {useRef, useState} from "react";
import HttpClient from './HttpClient';
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";


const StartPage = () => {

    const api_url = process.env.REACT_APP_API_URL;

    const [isRequest, setIsRequest] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [formMessage, setFormMessage] = useState('');
    const [showFormMessage, setShowFormMessage] = useState(false);
    const [formMessageType, setFormMessageType] = useState('');

    const webCamRef = useRef();
    const canvasRef = useRef();
    const [showWebCam, setShowWebCam] = useState(false);
    const webCamCapture = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models')
        ]).then(() => {
            navigator.mediaDevices.getUserMedia({
                video: {},
            }, stream => webCamRef.current.srcObject = stream)
            .then(() => {
                setShowWebCam(true);
            })
            .catch (err => {
                formAlert('Video device not found', 'error', true);
            });
        });
    };
    const webCamOnPlay = () => {
        setInterval( async () => {
            canvasRef.current.innerHTML = faceapi.createCanvas(webCamRef.current);
            const displaySize = {
                width: 300,
                height: 300
            };
            faceapi.matchDimensions(canvasRef.current, displaySize);
            const detections = await faceapi.detectAllFaces('webcam', new faceapi.TinyFaceDetectorOptions());
            const resizedDetections = faceapi.resizeResults(detections, displaySize);
            canvasRef.current.getContext('2d').clearRect(0, 0, 400, 400);
            faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        }, 100);
    };
    // const stopWebCamStream = () => {
    //     const stream = webCamRef.current.video.srcObject;
    //     const tracks = stream.getTracks();
    //     tracks.forEach(track => track.stop());
    //     webCamRef.current.video.srcObject = null;
    //     setShowWebCam(false);
    // };

    const resetForm = () => {
        setEmail('');
        setPassword('');
        setShowFormMessage(false);
    };

    const formAlert = (message, type, showStatus) => {
        setFormMessage(message);
        setFormMessageType(type);
        setShowFormMessage(showStatus);
    };

    const loginByWebCam = () => {

        webCamCapture();

        setTimeout(async function () {
            const image_src = webCamRef.current.getScreenshot();

            setIsRequest(true);
            setShowFormMessage(false);
            try {
                formAlert('', '', false);
                setShowFormMessage(false);

                const response = await HttpClient.post(api_url+'/security/login-webcam', {image_src});
                const responseData = response.data;
                if (responseData.status === 0) {
                    formAlert(responseData.message, 'error', true);
                } else if (responseData.status === 1) {
                    formAlert(responseData.message, 'success', true);
                    setTimeout(function () {
                        window.location = '/';
                    }, 2000);

                } else {
                    formAlert('Request Error', 'error', true);
                }
            } catch (error) {
                formAlert('Request Error', 'error', true);
            }

            setIsRequest(false);

        }, 10000);
    };

    const submitLoginForm = async () => {
        setIsRequest(true);
        try {
            formAlert('', '', false);
            setShowFormMessage(false);
            const response = await HttpClient.post(api_url+'/security/login', {
                email,
                password
            });
            const responseData = response.data;

            if (responseData.status === 0) {
                formAlert(responseData.message, 'error', true);
            } else if (responseData.status === 1) {
                formAlert(responseData.message, 'success', true);

                setTimeout(function() {
                    resetForm();
                    window.location = '/';
                }, 2000);

            } else {
                formAlert('Request Error', 'error', true);
            }
        } catch (error) {
            formAlert('Request Error', 'error', true);
        }
        setIsRequest(false);
    };

    const redirectToRegister = () => {
        window.location = '/register';
    };


    return (
        <div className={'start-page-container'}>
            <div className={'start-page-wrapper'}>
                <div className={'form-title'}>
                    <span className={'title'}>Dashboard</span>
                </div>
                <div className={'form-wrapper'}>
                    <input type="text" value={email} disabled={isRequest} onChange={(e) => setEmail(e.target.value)} placeholder={'User Email'} required autoComplete={'off'}/>
                    <input type="password" disabled={isRequest} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} required autoComplete={'off'}/>
                    {showFormMessage ? <div className={`form-message ${formMessageType}`}>{formMessage}</div> : ''}
                    {showWebCam ?
                        <div className={'web-cam-wrapper'}>
                            <Webcam id={'webcam'} audio={false} width={400} height={400} ref={webCamRef} screenshotFormat={'image/jpeg'} onPlay={webCamOnPlay}/>
                            <canvas ref={canvasRef} width="940" height="650" className='app__canvas'></canvas>
                        </div>
                        : ''}
                    <div className={'actions-buttons-wrapper'}>
                        <button className={'actions-button'} onClick={submitLoginForm} disabled={isRequest}>Login</button>
                        <button className={'actions-button'} onClick={loginByWebCam} disabled={isRequest}>Login by Webcam</button>
                        <button className={'actions-button'} disabled={isRequest} onClick={redirectToRegister}>Registration</button>
                    </div>
                </div>


            </div>


        </div>
    )
}
export default StartPage;